import { useEffect, useRef } from 'react'
import { sendGAEvent } from '@next/third-parties/google'
import { usePlayerStatus } from '@hooks'

export default function useAnalytics(stationId: string) {
  const loaded = useRef(false)
  const isPlaying = useRef(false)
  const playerStatus = usePlayerStatus()

  const sendHeartbeat = () => {
    try {
      if (isPlaying.current)
        sendGAEvent('event', 'listening', { screen_name: stationId })
    } catch (error) {
      if (error instanceof Error) {
        console.error(`Failed to send GA event: ${error.message}`)
      }
    }
  }

  useEffect(() => {
    isPlaying.current = playerStatus === 'playing'
  }, [playerStatus])
  useEffect(() => {
    if (loaded.current) return
    loaded.current = true

    const interval = setInterval(sendHeartbeat, 20000)
    sendHeartbeat()

    return () => {
      clearInterval(interval)
      loaded.current = false
    }
  }, [])
}
